<template>
  <TrainingAnswerPageBackground
    :items="getItems()"
    @continueTraining="continueTraining"
    @backTraining="backTraining"
    :trainingID="$route.query.type == 1 ? `词汇` : `语法与功能`"
  >
    <div class="small-training-score-text-top">
      <b-row>
        <b-col v-if="$route.query.type == 1">
          <span>
            恭喜你完成<span class="training-knowledge"> 词汇 </span>
            <span class="training-difficulty"
              >{{ getClassLevel(result.finishDifficultyTagId)}}</span
            >
            难度的攻关
          </span>
        </b-col>
        <b-col v-if="$route.query.type == 2">
          <span>
            恭喜你完成<span class="training-knowledge"> {{$route.query.trainingExamName}} </span>
            的靶向训练，你离中考冲刺又近了一步！
          </span>
        </b-col>
      </b-row>
      <b-row class="training-score-text">
        <b-col>
          获得训练答题
          <pybWord :pybNum="result.answerReward"></pybWord>
          星级奖励
          <pybWord :pybNum="result.starReward"></pybWord>，共计
          <span class="green">
            <pybWord
              :pybNum="result.answerReward + result.starReward"
            ></pybWord>
          </span>
        </b-col>
      </b-row>
      <b-row> <b-col>已纳入你的后勤补给! </b-col> </b-row>
      <b-row class="training-score-text-middle">
        <b-col>
          <span>
            本次任务还剩<span class="training-knowledge remaining-levels">{{ result.remainingQuantity }}</span
            >个训练模块的等待攻克，继续加油！
          </span>
        </b-col>
      </b-row>
      <b-row class="training-score-text-bottom">
        <b-col>
          <span
            >本次闯关质量为：
            <span
              v-for="item in result.star || 1"
              :key="item"
              class="iconfont icon-shoucang"
            ></span
          ></span>
        </b-col>
      </b-row>
    </div>
  </TrainingAnswerPageBackground>
</template>

<script>
import TrainingAnswerPageBackground from "@/components/backgroung/training-answer-page-background.vue";
import pybWord from "@/components/key-words/pyb-key-word.vue";
import { getFinish } from "../../api/english-small";
import { mapState } from "vuex";
export default {
  data() {
    return {
      result: {
        finishPage: "",
      },
    };
  },
  components: {
    TrainingAnswerPageBackground,
    pybWord,
  },
  created() {
    this.getFinishData();
  },
  computed: {
    ...mapState(["trainingData"]),
  },
  methods: {
    getFinishData() {
      getFinish({ summaryId: this.$route.query.summaryId,tagId:this.$route.query.type}).then((res) => {
        console.log("res", res);
        this.result = res.data.result;
      });
    },
    getClassLevel(level){
      if(level == '8.1'){
        return '8年级上'
      }
      if(level == '8.2'){
        return '8年级下'
      }
      return level + '年级'
    },
    continueTraining() {
      if (this.$route.query.type == 1 && this.result.isAllFinish) {
        this.$router.replace({
          name: "ModuleStartEffectEvaluation",
          query: {
            module: 1,
          },
        });
      } else {
        this.backTraining();
      }
      // getVocabularyFinish({}).then((res) => {
      //   console.log("res", res);
      //   this.finishPage = res.data.result;
      // });
    },
    getItems(){
      console.log("[this.result]",this.result)
      return [this.result]
    },
    backTraining() {
      if (this.$route.query.type == 1) {
        this.$router.replace({
          name: "SmallModuleWordList",
          query: {
            // module: 1,
          },
        });
      }
      if(this.$route.query.type == 2){
        this.$router.replace({
          name: "SmallModuleGrammarList",
          query: {
            // module: 1,
          },
        });
      }
    },
  },
};
</script>


<style scoped lang="scss">
.small-training-score-text-top {
  & > .row {
    padding: 0.8rem 0;
  }
}
.training-knowledge {
  color: $text-green;
  font-weight: bold;
  font-size: 1.2rem ;
}
.remaining-levels {
  padding: 0 0.2rem;
}
.green {
  color: $text-green;
}
.icon-shoucang {
  color: #ffc13b;
}
</style>